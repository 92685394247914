<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="4">
        <b-link to="/customers">
          <b-row>
            <b-col
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <img
                src="@/assets/images/icons/total-customer.png"
                alt="summery-image"
              />
              <p class="summery-count">
                {{ customers }}
              </p>
              <p class="summery-title">Total Clients</p>
            </b-col>
          </b-row>
        </b-link>
      </b-col>
      <b-col cols="12" md="4">
        <b-link to="/employees">
          <b-row>
            <b-col
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <img
                src="@/assets/images/icons/total-employee.png"
                alt="summery-image"
              />
              <p class="summery-count">
                {{ employees }}
              </p>
              <p class="summery-title">Total Employees</p>
            </b-col>
          </b-row>
        </b-link>
      </b-col>
      <b-col cols="12" md="4">
        <b-link to="/job-posts">
          <b-row>
            <b-col
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <img
                src="@/assets/images/icons/total-job-post.png"
                alt="summery-image"
              />
              <p class="summery-count">
                {{ jobs }}
              </p>
              <p class="summery-title">Total Posted Jobs</p>
            </b-col>
          </b-row>
        </b-link>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BCol, BLink, BRow } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
  },
  data() {
    return {
      customers: 0,
      employees: 0,
      jobs: 0,
    };
  },
  created() {
    this.getTotal();
  },
  methods: {
    async getTotal() {
      let res = await this.$store.dispatch("dashboard/getTotal");
      this.customers = res.data.customers;
      this.employees = res.data.employees;
      this.jobs = res.data.jobs;
    },
  },
};
</script>

<style scoped>
.summery-count {
  /* 32 bold */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  /* identical to box height */

  text-align: center;

  /* Blue Soft */

  color: #7190ef;
}

.summery-title {
  /* 18 medium */

  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  text-align: center;

  /* Text Additional */

  color: #79869f;
}
</style>
