<template>
  <b-row>
    <b-col cols="12">
      <b-row>
        <b-col>
          <summery />
        </b-col>
      </b-row>
      <b-row class="match-height">
        <b-col cols="12" lg="6">
          <dash-board-home-line-chart />
         
        </b-col>
        <b-col cols="12" lg="6">
          <dashboardHomeStatus />
        </b-col>
        <b-col cols="12">
          <documentExpireTable />
        </b-col>
        <b-col cols="12" class="mt-2">
          <sisterConcerns v-if="user.roles[0].name === 'superadmin'" />
          <customerCompany v-if="user.roles[0].name === 'admin'" />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { getLogedInUser } from "@/utils/auth";
import { BCol, BFormSelect, BRow } from "bootstrap-vue";
import customerCompany from "./customerCompany.vue";
import dashBoardHomeLineChart from "./dashboard-home-line-chart.vue";
import dashboardHomeStatus from "./dashboard-home-status.vue";
import sisterConcerns from "./sisterConcerns.vue";
import summery from "./summery.vue";
import documentExpireTable from "./document-expire-table.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormSelect,
    summery,
    dashBoardHomeLineChart,
    dashboardHomeStatus,
    sisterConcerns,
    customerCompany,
    documentExpireTable,
    
  },
  data() {
    return {
      user: JSON.parse(getLogedInUser()),
    };
  },
};
</script>
